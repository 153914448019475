<template>
  <div class="impressum">
    <div class="textpadding">
      <p>
        Impressum
        <br>
        Verantwortlich für den Inhalt der Webseite:
        <br><br>
        Karla Bergers<br>
        Sent-Jan-Straße 4<br>
        47652 Weeze<br>
        Karla.Bergers@bergers-beratung.de<br>
        <br><br>
        Andrea Blondin<br>
        Hoffnungsstr. 38<br>
        47441 Moers<br>
        ablondin@web.de<br>
        <br><br>
        Ilona Dörr<br>
        An der Fleuth 42a<br>
        47608 Geldern<br>
        ilona.doerr@gmx.de<br>
        <br><br>
        Sabine Kooy<br>
        Pappelweg 26<br>
        47647 Kerken<br>
        kooysabine@gmail.com<br>
        <br><br>
        Barbara Leuschner<br>
        Franziskanerstraße 5<br>
        47652 Weeze<br>
        info@mediationleuschner.dee<br>
        <br><br>
        Claudia Meister<br>
        Zollvereinstraße 230<br>
        45772 Marl<br>
        emailanmeister@googlemail.de<br>
        <br><br>
        Astrid Radmacher<br>
        Hubertusstraße 26<br>
        46485 Wesel<br>
        astrid@radmacher.me<br>
        <br>
        <br>
        Haftungsausschluss
        <br>
        Wir, die Autoren, übernehmen keinerlei Gewähr für die Aktualität, Richtigkeit,
        Vollständigkeit oder Qualität der bereitgestellten Informationen. Haftungsansprüche,
        die sich auf materielle oder immaterielle Schäden, die durch Nutzung oder Nichtnutzung
        fehlerhafter oder unvollständiger Information verursacht wurden, sind ausgeschlossen,
        sofern unsererseits kein nachweislich vorsätzliches oder grob fahrlässiges
        Verschulden vorliegt.
        <br>
        Dies gilt auch für Links, auf die diese Internetseite direkt oder indirekt verweist.
        Wir erklären ausdrücklich, dass zum Zeitpunkt der Linksetzung keine illegalen Inhalte
        auf den zu verlinkenden Seiten erkennbar waren. Wir haben keinen Einfluss auf die aktuelle
        und die künftige Gestaltung, die Inhalte oder die Urheberschaft der verlinkten Seiten.
        Deshalb distanzieren wir uns ausdrücklich von Inhalten aller verknüpften Seiten, die nach
        der Linksetzung verändert wurden. Wir übernehmen keine Haftung für illegale, fehlerhafte
        oder unvollständige Inhalte, sowie materielle oder immaterielle Schäden, die aus der
        Nutzung oder Nichtnutzung solcher Informationen entstehen.
        <br>
        <br>
        Urheberrecht
        <br>
        Wir sind bestrebt, auf unserer Internetseite die Urheberrechte der verwendeten Bilder
        und Texte zu beachten und von uns selbst erstellte Texte, Bilder und Grafiken zu nutzen.
        Eine Vervielfältigung oder Verwendung derselben ist nur mit unserer ausdrücklichen und
        schriftlichen Genehmigung gestattet.
        <br>
        Sollten Inhalte oder Bilder unserer Seite Rechte Dritter oder gesetzliche Bestimmungen
        verletzen, so bitten wir sie, uns zu benachrichtigen. Zu Recht beanstandete Passagen
        werden garantiert unverzüglich geändert bzw. entfernt, ohne dass sie einen
        Rechtsbeistand einschalten müssen.
        <br>
        <br>
        Datenschutzerklärung
        <br>
        Allgemeines
        <br>
        Die folgenden Hinweise geben Ihnen einen Überblick darüber, was mit ihren persönlichen
        Daten geschieht, wenn Sie unsere Internetseite besuchen. Persönliche Daten sind alle Daten,
        durch die Sie persönlich identifiziert werden können.
        <br>
        Wir nehmen den Schutz Ihrer persönlichen Daten sehr ernst und behandeln diese vertraulich
        und entsprechend der gesetzlichen Vorschriften sowie dieser Datenschutzerklärung.
        <br>
        Recht auf Auskunft, Berichtigung, Löschung, Sperrung
        <br>
        Sie haben das Recht die Berichtigung, Sperrung oder Löschung Ihrer Daten zu verlangen.
        Sie haben ein Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer
        persönlichen Daten zu erhalten. Sie können sich jederzeit unter der im Impressum
        angegebenen Adresse an uns wenden und haben ein Beschwerderecht bei der
        zuständigen Aufsichtsbehörde.
        <br>
        Erhebung von Nutzerdaten
        <br>
        Die Erhebung von Nutzerdaten erfolgt im Rahmen der gesetzlichen Vorschriften.
        Die Nutzung unseres Internetauftritts ist in der Regel weitgehend ohne Angabe
        personenbezogener Daten möglich.
        <br>
        Wenn Sie uns per E-Mail kontaktieren, werden Ihre Angaben zur Bearbeitung der Anfrage
        sowie für mögliche anschließende Fragen gespeichert. Sofern für einzelne Angebote, z.B.
        Kontaktanfrage, personenbezogene Daten, wie Name, Telefonnummer oder E-Mail-Adresse
        abgefragt werden, geschieht dies stets auf freiwilliger Basis und unter eindeutiger
        Zustimmung durch den Nutzer. Diese Daten geben wir in keinem Fall, bzw. nur mit Ihrer
        ausdrücklichen Einwilligung, an Dritte weiter. Die Daten werden auf geschützten Servern
        gespeichert. Dennoch können bei allen Datenübertragungen im Internet Sicherheitslücken
        entstehen. Ein lückenloser Schutz der Daten vor dem Zugriff Dritter ist nicht möglich.
        <br>
        Server-Log-Dateien
        Der Provider der Seiten erhebt und speichert automatisch Informationen in so
        genannten Server-Log-Dateien, die ihr Browser automatisch an uns übermittelt. Dies
        sind Browsertyp, Browserversion, verwendetes Betriebssystem, Referrer URL, Hostname
        deszugreifenden Rechners, Uhrzeit der Serveranfrage und IP -Adresse. Diese Daten werden
        nicht mit anderen Datenquellen zusammengeführt. Grundlage der Erfassung ist
        Art.6 Abs.1 lit.f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der
        fehlerfreien Darstellung und der Optimierung der Website. Hierzu müssen Server-Log-
        Files erfasst werden.
        <br>
        Anpassung der Datenschutzbestimmungen
        <br>
        Wir behalten uns eine gelegentliche Anpassung unserer Datenschutzbestimmungen vor, um den
        aktuellen gesetzlichen Anforderungen zu entsprechen oder um Änderungen unserer
        Leistungen in der Datenschutzerklärung zu berücksichtigen.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Impressum.vue',
};
</script>

<style lang="less" scoped>
  @import "../assets/styling/global";
</style>
